export const isStaging = () =>
  process.env.NODE_ENV !== 'production' ||
  (typeof window !== 'undefined' &&
    (window.location.hostname.endsWith('.staging.daily.co') ||
      window.location.hostname.endsWith('.now.sh') ||
      window.location.hostname.endsWith('.vercel.app')));

export const isRelease = () =>
  typeof window !== 'undefined' &&
  window.location.hostname.endsWith('.release.daily.co');

export const getDailyBotsDashboardUrl = () => {
  if (isStaging()) {
    return 'https://bots.staging.daily.co';
  }
  if (isRelease()) {
    return 'https://bots.release.daily.co';
  }
  return 'https://bots.daily.co';
};
